/* TODO: Get rid of this long and hard to maintain list and just use a clearfix class */

/* Internet Explorer */
.clearfix { zoom: 1; }

/* Everything else */
.clearfix:after,
.boxscore-container:after,
.boxscore-extras:after,
.bracket-game p:after,
#bracket-seed-list p:after,
#bracket-teams p:after,
.bracket-game p:after,
.cmsGameStatus:after,
.cmsGameStatus ul:after,
.cmsPageStatus:after,
.comment-container:after,
.comment-form:after,
.comment:after,
.game-listing:after,
.gameStatusConstruction:after,
.leader-highlight:after,
.login-options:after,
.login-options-body:after,
.login-options-footer:after,
.login-options-header:after,
.newsItemElement:after,
#photoGalleryControls:after,
#photoGalleryControls .layoutContainer:after,
.rapidContentConstruction:after,
.rosterPlayerInfoElement:after,
#megaDropDown .season:after,
.season-title:after,
.season .header:after,
#sport-colors:after,
#sport-slider:after,
.sport-tools:after,
.sport-tools ul:after,
.sport-tools ul li:after,
.subcomment:after,
#megaDropDown .subseason:after,
#survey_steps form:after,
.surveyForwardButton:after,
.tooltip-inner:after,
.tooltip-outer:after,
.widgetFooter:after,
.widgetHierarchy .singleLevel:after,
.widget-teamButtons a:after,
.widget-teamButtons .sport-colors:after,
.widget-leader:after,
.widget-leader table li p:after
{content:".";display:block;height:0;clear:both;visibility:hidden;}
