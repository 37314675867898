/* Game Center */

.logo-gradient {
  position: absolute;
  height: 90px;
  width: 100px;
}
.winners-notch {
  background: url($asset_path_for+'/app_images/game_center/winnersNotch.png');
  bottom: 0;
  height: 8px;
  left: 50%;
  margin: 0 0 0 -8px;
  position: absolute;
  width: 16px;
}

/* Game List */
.gameListTeam{
  height: 20px;
  line-height: 21px;
  clear: both;
  margin-bottom: 5px;
  overflow: hidden;
  .score{
    float: left;
    font-weight: bold;
    margin-right: 5px;
    padding-left: 10px;
    text-align: right;
    min-width: 6%;
  }
}
GameLiveVideo{
  width: 100%;
  text-align: center;
}

/* In Progress Highlights */
tr.in_progress .game_status {
  background-image: url($asset_path_for+"/app_images/game_center/inprogressBG.png");
  background-image: linear-gradient(to right, rgba(65,138,0,0) 0%,rgba(65,138,0,0.5) 100%); /* W3C */
  background-position: right 50%;
  background-repeat: repeat-y;
}
tr.in_progress .game_status img,
.boxscore-extras.in_progress p.details a img {
  box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, .65);
}
/* SPORT NGIN LIVE LINKS */
p.watchLive-live,p.watchLive-recap{
  display: block;
  height: 38px;
  margin: 15px 20px 15px 15px;
  text-decoration: none;
  a{
    display: block;
    float: right;
    height: 38px;
    margin: 0 -5px 0 0;
    text-indent: -999999px;
    width: 100%;
  }
}
p.watchLive-live {
  background: url($asset_path_for+"/app_images/live/watchLive-live.png") 0 0;
  a {
    background: url($asset_path_for+"/app_images/live/watchLive-live.png") 100% -38px;
  }
}
p.watchLive-recap {
  background: url($asset_path_for+"/app_images/live/watchLive-recap.png") 0 0;
  a {
    background: url($asset_path_for+"/app_images/live/watchLive-recap.png") 100% -38px;
  }
}
p.watchLive-live:hover, p.watchLive-recap:hover {
  background-position:   0   -76px;
  a{
    background-position: 100% -114px;
  }
}
p.watchLive-live:active, p.watchLive-recap:active {
  background-position:   0  -152px;
  a{
    background-position: 100% -190px;
  }
}
.boxscore-extras p.watchLive-link{
  float: right; margin-top: -2px;
  a{
    padding: 8px 5px 2px;
  }
}
