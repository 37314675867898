#networkNavMenuTitle {
  width: 100%;
  border-bottom: none;

  &, .theme-nav-back { background: $link-color-darken-10; }
  .theme-nav-title-text { padding-right: $slide-nav-title-height; }
}

#networkNavMenuSites {
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  z-index: 101;
  transition: left 200ms ease-in-out;
  @include flex-box(column);

  &, .theme-nav-title { background: $link-color-darken-10; }
  .network-nav-expanded & { left: 0; }
}
